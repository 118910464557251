/**
 * Title
 *
 * Description
 *
 * 1)
 */

.c_multi-option {
  list-style-type: none;
    margin-left: 0;
  margin-bottom: 0;
}


  .c_multi-option__option {
    display: inline-block;
    padding: 0;

    a {
      display: block;
      padding: map-get($my-spacing, xx-large) map-get($my-spacing, large);
      background-color: my-color(greyscale, vapor);
      font-family: $montserrat-font-family;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: bold;
      color: my-color(orange);

      &:hover, &:active, &:focus {
        background-color: my-color(orange);
        color: my-color(greyscale, white);
      }
    }
  }
