.c_top-section {
  @include bg-cover;
  @include tint-bg($opacity: 0.7);

  padding: map-get($my-spacing, huge) 0;
  background-image: url('../img/top-section.jpg');

  &, * {
    color: my-color(greyscale, white);
  }
}
