$c_block__vars: (
  header-size: 66,
  basic-size: 28,
);

$my-var-namespace: $c_block__vars;


.c_block {
  @include bg-cover;
  @include flex;

  position: relative;
  background-color: my-color(greyscale, white-smoke);
  width: 100%;
  text-align: center;

  &, * {
    color: $body-font-color;
  }

  @include no-pad-column(12);
  .row[data-blocks="2"] & { @media #{$large-up} { @include no-pad-column(6); } }
  .row[data-blocks="3"] & { @media #{$large-up} { @include no-pad-column(4); } }

  &, p {
    @include flex-font(this-var(basic-size));
  }
}

.c_block--edit {
  &::after {
    @include absolute-full; /* [1a] */

    content: '';
    opacity: 0;
    background-color: rgba(my-color(greyscale, black), 0.1); /* [1b] */
    transition: opacity map-get($my-transition-speed, fast) ease;
  }

  &:hover::after {
    opacity: 1;
  }

  & > * { /* [2] */
    position: relative;
    z-index: map-get($my-z-index, grass); /* [2a] */
  }
}


  .c_block--edit__new {
    @include flex;
    @include align-items(center);

    position: absolute !important; //sorry, overriding [data-color]
    z-index: map-get($my-z-index, flower) !important; //sorry, overriding [data-color]
    opacity: 0;
       top: 0;
    bottom: 0;
    padding-right: map-get($my-spacing, small);
     padding-left: map-get($my-spacing, small);

    .c_block:hover & {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }


  .c_block--edit__new--left,
  .c_block--edit__new--right {
    .row[data-blocks="3"] & {
      display: none;
    }
  }

  .c_block--edit__new--left {
    left: 0;
    background: -moz-linear-gradient(left,  rgba(0,0,0,0.30) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(0,0,0,0.30) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(0,0,0,0.30) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  .c_block--edit__new--right {
    right: 0;
    background: -moz-linear-gradient(right,  rgba(0,0,0,0.30) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(right,  rgba(0,0,0,0.30) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to left,  rgba(0,0,0,0.30) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  .c_block--edit__new--top {
    right: 0;
    left: 0;
    bottom: auto;
    text-align: center;
    padding: map-get($my-spacing, small) 0;
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.30) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.30) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0.30) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    i {
      margin: 0 auto;
    }
  }

  .c_block--edit__new--bottom {
    top: auto;
    right: 0;
    left: 0;
    text-align: center;
    padding: map-get($my-spacing, small) 0;
    background: -moz-linear-gradient(bottom,  rgba(0,0,0,0.30) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(bottom,  rgba(0,0,0,0.30) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top,  rgba(0,0,0,0.30) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    i {
      margin: 0 auto;
    }
  }


  .c_block--edit__left,
  .c_block--edit__right {
    & > * {
      float: left;
      opacity: 0;
      line-height: 1;
      text-shadow: map-get($my-text-shadow, standard);

      .c_block--edit:hover & {
        opacity: 0.75; // we have to set this separely before the bits below as otherwise it gets spat out below them, overriding the hover state.
      }
    }
  }

  .c_block--edit__left.c_block--edit__left,
  .c_block--edit__right.c_block--edit__right { // chaining because sadness
    & > *:hover {
      opacity: 1;
    }
  }


  .c_block--edit__left {
    &.c_block--edit__left { // chained to override specificity of tint-bg colour selector stuff, sorry :(
      position: absolute;
      top: rem-calc(10);
      left: rem-calc(10);
      z-index: map-get($my-z-index, postbox) !important; //sorry, overriding [data-color]
    }
  }

    .c_block--edit__swatch {
      height: 1em;
       width: 1em;
      border: 1px solid my-color(greyscale, white);
      margin-left: map-get($my-spacing, small);
      border-radius: 3px;

      @each $name, $color in $flat-colors {
        .c_block[data-color="#{$name}"] & {
          background-color: $color;
        }
      }
    }


  .c_block--edit__right {
    &.c_block--edit__right { // chained to override specificity of tint-bg colour selector stuff, sorry :(
      position: absolute;
        top: rem-calc(5);
      right: rem-calc(10);
      z-index: map-get($my-z-index, postbox) !important; //sorry, overriding [data-color]
    }
  }


  .c_block--edit__inputs {
    display: none; // hide by default

    .c_block[data-type] & {
      display: block; // only show if we have a data-type set
      margin-top: map-get($my-spacing, medium);
    }
  }


    .c_block__primary-wrap {
      @include grid-column(12); // set full width by default

      .row[data-blocks="1"] & { // and do more fancy stuff if we're inside a full width block as we got mo' space
        @include grid-column(12);

        @media #{$large-up} {
          @include grid-column(4);
        }
      }

      .c_block[data-type="text"] & {
        display: none !important; // hide primary if text type selected
      }
    }


    .c_block__secondary-wrap {
      @include grid-column(12); // set full width by default

      .row[data-blocks="1"] & { // and do more fancy stuff if we're inside a full width block as we got mo' space
        @include grid-column(12);

        @media #{$large-up} {
          @include grid-column(8);
        }
      }

      .c_block[data-type="text"] & {
        @include grid-column(12);
      }
    }


      .c_block__primary {
        text-align: center; // default to text align center for when this input is full width
        font-family: $montserrat-font-family !important;

        .row[data-blocks="1"] & { // but if inside the full variant...

          @media #{$large-up} { // on medium up we're going side by side...
            text-align: right; // so set this to text align right, so they sit snugly next to each other.
            margin-bottom: 0;
          }
        }

        &[type="date"] {
          @include flex-font(40);
        }

        .c_block--edit & {
          margin-bottom: map-get($my-spacing, medium);
        }
      }


      .c_block__secondary {
        &, p {
          @include flex-font(36);
          line-height: 1;
        }

        text-align: center; // default to text align left
        margin-bottom: 0;
        font-family: $lato-font-family;

        .row[data-blocks="1"] & { // but if inside the full variant ...
          @media #{$large-up} {
            text-align: left; // so set this to text align left, so they sit snugly next to each other.
          }

          &, p {
            @include flex-font(44);
          }
        }

        .row[data-blocks="1"] .c_block[data-type="text"] & { // but if inside the full variant AND with data-type of text...
          text-align: center; // so set this to text align left, so they sit snugly next to each other.
        }

        .c_block[data-type="text"] & {
          font-family: $bitter-font-family;
          font-style: italic;
        }

        .c_block--edit & {
          height: rem-calc(140);
        }

        p:last-child {
          margin-bottom: 0;
        }
      }





@each $color, $tint-color in $flat-colors {
  [data-color="#{$color}"] {
    @include tint-bg($tint-color, auto);

    $lightness: check-lightness($tint-color);

    @if ($lightness == dark) {
      &, *, *:hover, *:active, *:focus, input[type] {
        color: my-color(greyscale, white);
      }
    } @else {
      &, *, *:hover, *:active, *:focus, input[type] {
        color: my-color(greyscale, black);
      }
    }
  }
}




  .c_block__inner {
    @include flex;
    @include flex-column;
    @include justify-content(center);

    width: 100%;

    @include o_spacer(large);

    .c_block--edit & {
      position: relative; // so we can absolutely position the edit option buttons inside this context
    }
  }


    .c_block__header {
      @include flex-font(52);

      [data-type="date"] & {
        @include flex-font(66);

        line-height: 0.7;
        margin-bottom: map-get($my-spacing, medium);
      }

      [data-type="number"] & {
        @include flex-font(128);

        line-height: 0.7;
        margin-bottom: map-get($my-spacing, medium);
      }

      .row[data-blocks="1"] & {
        @media #{$large-up} {
          margin-bottom: 0 !important;
        }
      }

      .row[data-blocks="1"] [data-type="text"] & {
        font-family: $bitter-font-family;
        font-style: italic;
      }

      .row[data-blocks="3"] [data-type="date"] & {
        @media #{$large-up} {
          font-size: 3.7vw;
        }
      }

      [data-type="date"] & {
        @media #{$large-up} {
          font-size: 4vw;
        }
      }

      [data-type="number"] & {
        @media #{$large-up} {
          font-size: 8vw;
        }
      }
    }

