/**
 * Radio tab component
 */

.c_radio-tab {
  display: inline-block; // so it can be centered when necessary
  position: relative;
  /* This part sucks */
  clear: both;
  min-height: 50px;
}


  .c_radio-tab__tab {
    float: left;

    [type=radio] {
      display: none;

      &:checked ~ label {
        background-color: rgba(my-color(greyscale, jet), 0.8);
        z-index: 2;
      }
    }
  }


  .c_radio-tab__label {
    position: relative;
    background-color: rgba(my-color(greyscale, jet), 0.5);
    padding: rem-calc(10) rem-calc(15);
    font-size: rem-calc(18);

    &, &:hover, &:active, &:focus {
      color: my-color(greyscale, white);
    }

    &.c_radio-tab__label { // chain to override foundation specificity
      margin: 0; // override default foundation margin for labels next to radios
    }
  }
