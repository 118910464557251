.c_avatar {
   width: rem-calc(100);
  height: rem-calc(100);
  border: 2px solid my-color(greyscale, white);
  border-radius: 50%;
  margin-left: map-get($my-spacing, small);
}

.c_avatar--small {
   width: rem-calc(50);
  height: rem-calc(50);
}
