.button.full {
  display: block;
  width: 100%;
}

.button.fat {
  font-size: rem-calc(18);
  padding: 1.2em 1.56em;

  @media #{$medium-up} {
    font-size: rem-calc(22);
  }
}

.button--twitter {
  @include bg-color(twitter);

  margin-top: map-get($my-spacing, medium);
}

.button--twitter-reverse {
  margin-top: map-get($my-spacing, medium);
  background-color: my-color(greyscale, white);
  color: my-color(twitter);

  &:hover, &:active, &:focus {
    background-color: my-color(twitter, darker);
    color: my-color(greyscale, white);
  }
}
