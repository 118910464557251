.c_image-block-grid {
  @include block-grid(3, $spacing: 0);

  a {
    &:hover {
      @include tint-bg;
    }
  }

  @media #{$medium-up} {
    @include block-grid(5, $spacing: 0);
  }

  @media #{$medium-up} {
    @include block-grid(7, $spacing: 0);
  }
}
