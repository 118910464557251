.c_main-footer {
  @include clearfix;

  background-color: my-color(greyscale, jet);
  padding: map-get($my-spacing, medium-large);

  &, * {
    font-size: rem-calc(18);
  }

  a {
    color: my-color(greyscale, white);
    opacity: 0.5;

    &:hover, &:active, &:focus {
      opacity: 1;
    }
  }
}

  .c_main-footer__left {
    @media #{$medium-up} {
      float: left;
    }
  }

  .c_main-footer__right {
    padding-top: rem-calc(20);

    @media #{$medium-up} {
      float: right;
    }
  }
