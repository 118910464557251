/* SETTINGS - initial settings needed for use in foundation settings */
@import "settings/type";
@import "settings/foundation-settings";


/* FOUNDATION */
@import '../bower/foundation/scss/foundation.scss';


/* TOOLS: FUNCTIONS [add this first as we need it for _variables.css] */
@import "tools/functions";


/* SETTINGS */
@import "settings/variables";
@import "settings/colors";
@import "settings/keyframes";


/* TOOLS: MIXINS [add this after _variables.scss as we may reference variables here] */
@import "tools/mixins";


/* BASE */
@import "base/foundation-overrides";


/* OBJECTS */
@import "objects/layout";
@import "objects/last-child";
@import "objects/spacer";
@import "objects/horizontal-list";
@import "objects/subtle-input";
@import "objects/tag";


/* COMPONENTS */
@import "components/logo";
@import "components/button";
@import "components/block";
@import "components/reveal-modal";
@import "components/image-block-grid";
@import "components/multi-option";
@import "components/radio-tab";
@import "components/swatches";
@import "components/top-bar";
@import "components/avatar";
@import "components/section/top";
@import "components/section/signup";
@import "components/section/profile-top";
@import "components/section/share";
@import "components/section/action";
@import "components/main-footer";
@import "components/response-message";


/* THEMES */
@import "themes/emphasise";



/* STATES */



/* UTILITIES */
@import "utilities/utilities";


/* HACKS */
@import "shame";










.test-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    width: 800px;
    height: 600px;
    background-image: url("http://unsplash.it/800/600/");
    color: white;
}
