.c_swatches {
  display: none;
  margin-left: map-get($my-spacing, small);
}


  .c_swatches__swatch {
    display: inline-block;
     width: 1em;
    height: 1em;
    margin-top: 0.4em;
    border: 1px solid my-color(greyscale, white);

    @each $name, $color in $flat-colors {
      &[data-color="#{$name}"] {
        background-color: $color;
      }
    }
  }
