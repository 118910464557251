@-webkit-keyframes outerGlow {
  from {
    -webkit-box-shadow: 0px 0px 80px 0px rgba(my-color(twitter, darker), 1);
    -moz-box-shadow: 0px 0px 80px 0px rgba(my-color(twitter, darker), 1);
    box-shadow: 0px 0px 80px 0px rgba(my-color(twitter, darker), 1);
  } 50% {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(my-color(twitter, darker), 1);
    -moz-box-shadow: 0px 0px 0px 0px rgba(my-color(twitter, darker), 1);
    box-shadow: 0px 0px 0px 0px rgba(my-color(twitter, darker), 1);
  } to {
    -webkit-box-shadow: 0px 0px 80px 0px rgba(my-color(twitter, darker), 1);
    -moz-box-shadow: 0px 0px 80px 0px rgba(my-color(twitter, darker), 1);
    box-shadow: 0px 0px 80px 0px rgba(my-color(twitter, darker), 1);
  }
}
