.top-bar {
  ul li > a {
    font-family: $montserrat-font-family;
  }
}


.top-bar--home {
  position: absolute;
    top: 0;
  right: 0;
   left: 0;
  z-index: map-get($my-z-index, shed);
  background-color: transparent;

  &.top-bar--home {
    &.top-bar--home {
      &.top-bar--home {
        &:not(.expanded) {
          li, a {
            background-color: transparent;
          }

          li:not(.name) {
            a {
              &:hover {
                background-color: rgba(my-color(greyscale, black), 0.75);
              }
            }
          }

          .has-dropdown.hover {
            background-color: rgba(my-color(greyscale, black), 0.5);
          }

          .dropdown {
            a {
              background-color: rgba(my-color(greyscale, black), 0.5);

              &:hover {
                background-color: rgba(my-color(greyscale, black), 0.75);
              }
            }
          }
        }
      }
    }
  }

  .title-area {
    .name {
      a {
        padding: 0 map-get($my-spacing, medium);
        line-height: 1;
      }
    }
  }

  .c_logo {
    margin-top: map-get($my-spacing, medium);
  }
}
