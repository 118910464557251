.u_hidden {
  display: none !important;
}

/**
 * General radius utility
 *
 * Adds a subtle border radius to the element
 */

.u_radius { border-radius: map-get($my-border-radius, 'tiny') !important; }


.u_mb-med { margin-bottom: map-get($my-spacing, medium) !important; }
.u_mb-med--so { @media #{$small-only} { margin-bottom: map-get($my-spacing, medium) !important; } }

.u_pb-0 { padding-bottom: 0 !important; }



.u_glow {
  -webkit-animation-name: outerGlow;
          animation-name: outerGlow;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}


.u_text-right--mu { @media #{$medium-up} { text-align: right !important; } }
