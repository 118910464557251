/**
 * Emphasise theme
 *
 * Currently just makes all <p> tags a bit bigger. Can be applied to the body
 *  or specific elements.
 */

.t_emphasise {
  p {
    font-size: rem-calc(22);
  }
}
