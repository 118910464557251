.o_tag {
  display: inline-block;
  padding: 20px;
  background-color: rgba(0,0,0,0.5);
  line-height: 1.2;

  &, &:hover, &:focus, &:active {
    color: #fff;
  }

  &:hover {
    background-color: rgba(0,0,0,1);
  }
}
