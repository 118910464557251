/**
 * Reveal modal component tweaks
 *
 * Extends Foundations default `.reveal-modal`
 */

.reveal-modal--image {
  padding: 0;
  height: rem-calc(280);
  overflow-y: scroll;

  &:focus {
    outline: none; // stop Mike getting annoyed at when he scrolls blue bars taunt him
  }

  @media screen and ( min-height: rem-calc(620) ){
    height: rem-calc(420);
  }

  @media screen and ( min-height: rem-calc(760) ){
    height: rem-calc(560);
  }

  @media screen and ( min-height: rem-calc(900) ){
    height: rem-calc(700);
  }
}

