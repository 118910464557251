.c_response-message {
  display: inline-block;
  padding: rem-calc(6) rem-calc(12);
}

.c_response-message--success {
  @include bg-color(green);

  &, &:hover, &:active, &:focus {
    color: my-color(greyscale, white);
  }
}

.c_response-message--failure {
  @include bg-color(red);

  &, &:hover, &:active, &:focus {
    color: my-color(greyscale, white);
  }
}

.c_response-message__link {
  font-weight: bold;

  &, &:hover, &:active, &:focus {
    text-decoration: underline;
  }
}
