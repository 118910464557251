.c_profile-top-section {
  padding-top: $topbar-height + map-get($my-spacing, large);
  padding-bottom: map-get($my-spacing, large);
  background-color: my-color(greyscale, jet);

  &, * {
    color: my-color(greyscale, white);
  }
}


  .c_profile-top-section__year {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    text-align: center;
    font-family: $montserrat-font-family;
    font-size: 180px;
    opacity: 0.1;
    display: flex;
    justify-content: center;

    @media #{$medium-up} {
      top: 0;
      font-size: 220px;
    }
  }
