$montserrat-font-family: 'Montserrat', sans-serif;
      $lato-font-family: 'Lato', sans-serif;
    $bitter-font-family: 'Bitter', Georgia, serif;






$small-font-reducer: 0.75;
