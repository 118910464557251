.row--no-gutter > .columns {
  padding-right: 0;
   padding-left: 0;
}

.row--flex {
  @include flex;
  @include flex-wrap(wrap); // wrap the columns as normal behaviour, don't squish em all

  & > .columns {
    @include flex;
    @include flex-column;
    @include justify-content(center);

    & > * {
      @include flex;
      @include flex-column;
      @include justify-content(center); // vertical center the blocks inner content
    }
  }
}

.row--flex-bottom {
  @include flex;
  @include flex-wrap(wrap);

  & > * {
    @include flex;
    @include flex-column;
    @include justify-content(flex-end);

    & > * {
      @include flex;
      @include flex-column;
      @include justify-content(flex-end); // vertical center the blocks inner content
    }
  }
}




.row--full-width {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: none;

  .row {
    &.row { // chaining to override Foundation specificity `form .row .row`
      margin: 0 auto;
      width: 100%;

      @media #{$medium-up} {
        padding-right: map-get($my-spacing, large);
         padding-left: map-get($my-spacing, large);
      }
    }
  }
}
