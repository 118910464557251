/**
 * Horizontal list object
 *
 * Remove normal styling and float each item to sit horizontally
 *
 * 1) MAGIC EM NUMBERS: Set these in em's so we can add a font-size to the list
 *     and the whole list will grow in proportion.
 */

.o_horizontal-list {
  @include undercover-list;
  @include clearfix;

  & > li {
    display: inline-block;

    &:last-of-type {
      margin-right: 0;
    }
  }

  a {
    font-size: inherit;
  }
}


.o_horizontal-list--line {
  & > li {
    position: relative;
    padding-right: rem-calc(15); /* [1] */
    margin-right: rem-calc(11);

    &::after {
      content: '';
      position: absolute;
         top: 0.22em;
       right: 0;
      bottom: 0.22em;
      width: 1px;
      background-color: rgba(my-color(greyscale, white), 0.5);
    }

    &:last-of-type::after {
      content: none;
    }
  }
}
