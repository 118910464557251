.c_action-section {
  display: block;
  padding-right: map-get($my-spacing, medium);
   padding-left: map-get($my-spacing, medium);
  background-color: my-color(greyscale, white-smoke);
  text-align: center;

  p {
    font-family: $bitter-font-family;
    font-size: rem-calc(18);
    font-style: italic;
  }

  i {
    @include flex-font(80);
  }

  &, &:hover, &:active, &:focus {
    color: my-color(greyscale, oil);
  }
}

.c_action-section--save {
  @include bg-color(green);

  &, &:hover, &:active, &:focus {
    color: my-color(greyscale, white);
  }
}
