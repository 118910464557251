// Because we've not set up autoprefixer we're doing the hacky things for this for now. Replace with autoprefixer in longer term.

@mixin flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin align-items($alignment) {
    -webkit-box-align: $alignment;
  -webkit-align-items: $alignment;
       -ms-flex-align: $alignment;
          align-items: $alignment;
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
      -ms-flex-wrap: $wrap;
          flex-wrap: $wrap;
}

@mixin flex-column {
    -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

@mixin justify-content($justify) {
         -webkit-box-pack: $justify;
  -webkit-justify-content: $justify;
            -ms-flex-pack: $justify;
          justify-content: $justify;
}




@mixin o_spacer($variant: null) {
    padding-top: map-get($my-spacing, medium);
  padding-bottom: map-get($my-spacing, medium);

  @media #{$medium-up} {
       padding-top: map-get($my-spacing, large);
    padding-bottom: map-get($my-spacing, large);
  }

  @if ($variant == large) {
       padding-top: map-get($my-spacing, large);
    padding-bottom: map-get($my-spacing, large);

    @media #{$medium-up} {
         padding-top: map-get($my-spacing, xx-large);
      padding-bottom: map-get($my-spacing, xx-large);
    }
  }
}






@mixin no-pad-column($columns) {
  width: #{$columns / 12 * 100}%;
}







/**
 * The vertical-align mixin
 *
 * 1) This requires there to be a .class-name__center-me element
 */

@mixin vertical-align-table {
  display: table;

  &__center-me { /* [1] */
    display: table-cell;
    vertical-align: middle;
  }
}






@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}






@mixin bg-cover($variant: null) {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @if ($variant == fixed) {
    background-attachment: fixed;
  }
}






/**
 * bg-color mixin
 *
 * This mixin allows us to set our background-color & optionally a background-
 *  color for our hover, focus & active states too. This means we can simple do
 *  something like this: `.c_button--pink { @include bg-color(pink); }`.
 *  The mixin will then grab the relevant colours and build the styles.
 *
 * 1) Optional parameter for colour $variant
 *  1a) Optional parameter for $hover
 * 2) Create a new variable $hover-color and set it to none. This is needed
 *     otherwise it will be set inside an @if and it seems that this then ties
 *     the scope to that @if, which errors out when we try and access it later.
 *  2a) ...and another one: $bg-color
 * 3) If the colour is greyscale...
 *  3a) ...and the variant is null, then set it to aluminum.
 *  3b) Get the colour using our helper function my-color()
 *  3c) If hover is set, then lets get the hover background colour. As we don't
 *       have variants of greyscale colours lets scale this one instead.
 * 4) Else...
 *  4a) Get the colour using our helper function my-color()
 *  4b) If hover is set then get our $hover-color
 * 5) Now set the background-color to what we set our $bg-color var too earlier
 * 6) And if we've got a hover state, then lets set that for hover, focus &
 *     active states.
 *  6a) If not, then set our $bg-color to the hover, focus & active states too.
 */

@mixin bg-color( $color, $variant: null, $hover: true ) { /* [1][1a] */
  $hover-color: null; /* [2] */
  $bg-color: null; /* [2a] */

  @if ($color == greyscale) { /* [3] */

    @if ($variant == null) { $variant: aluminum; /* [3a] */ }
    $bg-color: my-color($color, $variant); /* [3b] */
    @if ($hover) { $hover-color: scale-color($bg-color, $lightness: -10%); /* [3c] */ }

  } @else { /* [4] */

    $bg-color: my-color($color, $variant); /* [4a] */
    @if ($hover) { $hover-color: my-color($color, dark); } /* [4b] */

  }

  background-color: $bg-color; /* [5] */

  @if ($hover) {
    &:hover, &:focus, &:active { background-color: $hover-color; /* [6] */ }
  } @else {
    &:hover, &:focus, &:active { background-color: $bg-color; /* [6a] */ }
  }
}







/**
 * Undercover List mixin
 *
 * 1) Should be applied to a <ul> or <ol> element
 */

@mixin undercover-list {
  list-style-type: none;
  margin-left: 0; margin-bottom: 0;

  & > li {
    padding:0;
  }
}






@mixin header-underline($thickness:rem-calc(4)) {
  padding-bottom: 0.2em;
  border-bottom: $thickness solid rgba(0,0,0,0.05);
}






/**
 * Absolute center mixin
 */

@mixin absolute-center {
  position: absolute;
  right:  0;
  left:   0;
  top:    -9999px;
  bottom: -9999px;
  width:      100%;
  min-width:  100%;
  margin: auto 0;
}






/**
 * Inset border mixin
 *
 * Create an after element that stretches to fill the current element and add a
 *   subtle inset border
 *
 * CAUTION: Due to this pseudo element covering the original element we need to
 *  set pointer events to none ([5]) but this only works on IE11+. As a result
 *  this mixin can only be used on elements where we it isn't critical to be
 *  able to click on the inner elements (e.g. a link with an image in, the link
 *  will still be clickable but the image won't, and that's fine)
 *
 * 1) Set the position context to the parent
 * 2) Set the positioning of the pseudo after to fill the parents position
 * 3) Give our pseudo empty content (without this the after won't display)
 * 4) Add a subtle border with an opacity that can be overriden
 * 5) Stop all pointer events on this new pseudo element (works IE11+)
 */

@mixin inset-border($opacity: 0.05) {
  position: relative;

  &::after {
    content: '';  /* [3 */
    position: absolute; /* [2] */
    top:    0; /* [2] */
    right:  0; /* [2] */
    bottom: 0; /* [2] */
    left:   0; /* [2] */
    border: 1px solid rgba(0, 0, 0, $opacity); /* [4] */
    pointer-events: none; /* [5] */
  }
}






/**
 * Absolute full mixin
 *
 * This mixin allows us to include an absolutely positioned element and pass
 *  in optional parameters to set each of the top/right/bottom/left properties
 *
 * 1) Optional parameters for top/right/bottom/left
 * 2) If null is passed for any of the parameters then don't specify that
 *      property, else do specify it
 *      (this allows us to only set the parameters we want)
 */

@mixin absolute-full($top:    0,
                     $right:  0,
                     $bottom: 0,
                     $left:   0) { /* [1] */
  position: absolute;
  @if ($top    != null) { top:    $top; } /* [2] */
  @if ($right  != null) { right:  $top; } /* [2] */
  @if ($bottom != null) { bottom: $top; } /* [2] */
  @if ($left   != null) { left:   $top; } /* [2] */
}






/**
 * Tint bg mixin
 *
 * Include this when you've got a background image that needs darkening a tad
 *  to make sure light text on top of this is legible.
 *
 * 1) Set the positioning context...
 *  1a) ...so we can absolutely position our pseudo ::after element within this
 *       context...
 *  1b) ...that darkens the bg a little.
 * 2) Due to adding an overlay tint, we now need to raise everything else above
 *     this, otherwise they'll get darkened too. There's no nice way of doing
 *     this, here we use a descendant selector, which may provide specificity
 *     issues down the line. But it's the best I can think of right now.
 *  2a) Raise it on up.
 */

@mixin tint-bg($color: my-color(greyscale, black), $blend-mode: false, $opacity: 0.75, $pseudo: before) {
  $lightness: check-lightness($color);

  position: relative; /* [1] */

  &::#{$pseudo} {
    @include absolute-full; /* [1a] */

    content: '';
    background-color: rgba($color, $opacity); /* [1b] */

    @if ($blend-mode == auto) {
      @if ($lightness == dark) {
        mix-blend-mode: darken;
      } @else {
        mix-blend-mode: lighten;
      }
    } @else if ($blend-mode){
      mix-blend-mode: $blend-mode;
    }
  }

  & > * { /* [2] */
    position: relative;
    z-index: map-get($my-z-index, grass); /* [2a] */
  }
}






/**
 * Last child mixin
 *
 * Enables us to access all the last children, to three levels. Useful to
 *  make sure elements inside elements are selected, like a button inside a
 *  form.
 *
 * Primarily used to remove the space at the bottom of an element.
 *
 * Usage:
 * =============================================================================
 * @include last-child {
 *   margin-bottom: 0;
 * }
 * =============================================================================
 *
 * Originally grabbed form CSS Tricks:
 *  https://css-tricks.com/spacing-the-bottom-of-modules/
 */

@mixin last-child {
  & > *:last-child,
  & > *:last-child > *:last-child,
  & > *:last-child > *:last-child > *:last-child {
    @content
  }
}







/**
 * Flex font mixin
 *
 * Handles sizing smaller on mobile and normal on medium up
 */

@mixin flex-font($size) {
  font-size: rem-calc($size) * $small-font-reducer;

  @media #{$medium-up} {
    font-size: rem-calc($size);
  }
}
