.o_subtle-input {
  font-size: rem-calc(18);

  &.o_subtle-input {

    &, &:active, &:focus {
      background-color: rgba(my-color(greyscale, jet), 0.6);
      border-width: 0 0 3px 0;

      &:valid {
        background-color: rgba(my-color(greyscale, jet), 0.1);
      }
    }

    // these have to be separate declarations as our minifier puts them on the same line, and when a browser sees one it doesn't like it invalidates the whole line, thus breaking it.
    &::-webkit-input-placeholder { color: rgba(my-color(greyscale, white), 0.5); }
              &:-moz-placeholder { color: rgba(my-color(greyscale, white), 0.5); }
             &::-moz-placeholder { color: rgba(my-color(greyscale, white), 0.5); }
         &:-ms-input-placeholder { color: rgba(my-color(greyscale, white), 0.5); }
  }
}


  .o_subtle-input--large {
    &.o_subtle-input--large {
      font-size: rem-calc(108);
      height: auto;
    }
  }
